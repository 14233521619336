import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    alert: null,
    user: null,
    authenticated: false,
    reservoir: null,
  },
  mutations: {
    changeUser(state, user) {
      state.user = user;
    },
    changeAuth(state, authState) {
      state.authenticated = authState === "signedin";
    },
    changeReservoir(state, reservoir) {
      state.reservoir = reservoir;
    },
    showAlert(state, alert) {
      // Expects {color: 'red, success, etc...', type: 'success|error|info', text: ""}
      if (alert.type === "success") alert.color = "rgb(244, 137, 82)";
      else if (alert.type === "error") alert.color = "#7a3799";
      else alert.color = "#2c3e50";
      state.alert = alert;
    },
  },
  actions: {},
  modules: {},
});
