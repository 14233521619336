<template>
  <v-container>
    <div>
      <amplify-authenticator>
        <amplify-sign-out></amplify-sign-out>
      </amplify-authenticator>
    </div>
    <div style="padding: 10px;">
      <v-row>
        <v-col
          sm="12"
          md="6"
          lg="4"
          v-for="(category, index) in categories"
          :key="index"
        >
          <v-card>
            <v-card-title>{{ category.name }}</v-card-title>
            <v-card-text>
              <div v-for="(account, aIndex) in category.accounts" :key="aIndex">
                <v-row>
                  <v-col>
                    {{ account.name }}
                  </v-col>
                  <v-col>
                    {{ account.balance }}
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Home",

  data() {
    return {
      categories: [
        {
          name: "Income",
          accounts: [
            {
              name: "Spark Geo",
              balance: 0,
            },
            {
              name: "Integrated Sustainability",
              balance: 0,
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped></style>
