<template>
  <div class="home">
    <v-img
      :src="require('@/assets/img/cairns_white.png')"
      width="100"
      class="logo-img"
      contain
    ></v-img>
    <div class="auth" v-if="!authenticated">
      <amplify-authenticator>
        <amplify-sign-in
          header-text="Sign in if you are a member of the Cairns family"
          hide-sign-up
          slot="sign-in"
          username-alias="email"
        ></amplify-sign-in>
      </amplify-authenticator>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",

  computed: {
    authenticated() {
      return this.$store.state.authenticated;
    },
  },
};
</script>

<style scoped>
.logo-img {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
}
.home {
  width: 100%;
  text-align: center;
  background: radial-gradient(#2c3e50, rgb(244, 137, 82));
}
.auth {
  display: inline-block;
  margin: 0 auto;
}
</style>
