<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";

export default {
  name: "App",
  created() {
    onAuthUIStateChange((authState, authData) => {
      this.$store.commit("changeUser", authData);
      this.$store.commit("changeAuth", authState);

      if (authState !== "signedin") {
        this.$router.push("/").catch(() => {
          // pass
        });
      } else if (this.$route.name !== "home") {
        this.$router.push({ name: "home" }).catch(() => {
          // pass
        });
      }
    });
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.$store.commit("changeUser", user);
        this.$store.commit("changeAuth", "signedin");
        if (this.$route.name !== "home") {
          this.$router.push({ name: "home" }).catch(() => {
            // pass
          });
        }
      })
      .catch(() => {
        //pass
      });
  },
  beforeDestroy() {
    return onAuthUIStateChange;
  },
};
</script>

<style>
#app {
  font-family: Dosis;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

:root {
  /* Colors */
  --amplify-primary-color: #2c3e50;
  --amplify-font-family: Dosis;
}
</style>
