const awsmobile = {
  aws_project_region: "us-west-2",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_DSO8kLBJ4",
  aws_user_pools_web_client_id: "798jedvm9l0k7h5iiem9fpb3ec",
  aws_cloud_logic_custom: [
    {
      name: "api",
      endpoint:
        "https://2esgfll6fh.execute-api.us-west-2.amazonaws.com/eft-development",
      region: "us-west-2",
    },
  ],
};

export default awsmobile;
